
  import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
  import { ValidationProvider } from "vee-validate";
  import { HasProperyPart } from "@/mixins/has-property-part";
  import { FormMoney, FormInput, FormTextarea } from "@/components/forms";

  @Component({
    components: {
      InputRadio: () => import("@/components/forms/property-radio.vue"),
      FormMoney,
      FormInput,
      FormTextarea,
      ValidationProvider,
    },
  })
  export default class ProperyPartInstallationRollerShutters extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: ProperyInstallationRollerShutters;

    localValue: ProperyInstallationRollerShutters = {
      automatic: null,
      has_roller_shutters: null,
      material: [],
      material_other: null,
      full_installation: null,
    };

    toggleArrayProperty(property: string[], value: string) {
      this.toggleArrayPropertyValue(property, value);

      this.handleInput();
    }

    resetValues() {
      this.localValue.automatic = null;
      this.localValue.material = [];
      this.localValue.material_other = null;
      this.localValue.full_installation = null;
      this.handleInput();
    }

    @Emit("input")
    handleInput() {
      return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: ProperyInstallationRollerShutters) {
      this.localValue = newValue;
    }
  }
